import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Typography } from "@material-ui/core"

const Header = styled.div`
  ${({ theme }) => `
    margin-bottom: ${theme.spacing(2)}px;
  `}
`

const Title = styled(Typography)`
  font-family: ${({ theme }) => theme.typography.secondary};
`

const Description = styled(Typography)`
  ${({ theme }) => `
      color: ${theme.palette.grey[700]}
  `}
`

const ListingHeader = ({ title, description }) => {
  return (
    <Header>
      {title && (
        <Title component="h2" variant="h5">
          {title}
        </Title>
      )}
      {description && (
        <Description component="p" variant="subtitle1">
          {description}
        </Description>
      )}
    </Header>
  )
}

ListingHeader.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
}

export default ListingHeader
