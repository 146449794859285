import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import {
  Typography,
  Grid,
  Card,
  CardActionArea,
  CardContent,
  Link,
} from "@material-ui/core"
import { Link as GatsbyLink } from "gatsby"
import BackgroundImage from "gatsby-background-image"

import { getArticleLink } from "../utils/article"

import ListingHeader from "./listing-header"

const Container = styled.div`
  ${({ theme }) => `
    margin-bottom: ${theme.spacing(4)}px;
  `}
`

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`

const StyledMedia = styled(BackgroundImage)`
  height: 0;
  padding-top: calc(300 / 500 * 100%);
  background-position: 80% center;
  background-size: cover;
  background-repeat: no-repeat;
`

const ArticleListing = ({ title, description, articles }) => {
  if (!articles || !articles.length) return null

  return (
    <Container>
      <ListingHeader title={title} description={description} />
      <Grid container spacing={2}>
        {articles.length > 0 &&
          articles.map((article) => {
            const {
              frontmatter: { title, slug, thumbnail },
            } = article
            const articleLink = getArticleLink(slug)

            return (
              <Grid item xs={6} sm={4} md={3} key={articleLink}>
                <StyledCard>
                  <Link component={GatsbyLink} to={articleLink}>
                    <CardActionArea>
                      {thumbnail && (
                        <StyledMedia fluid={thumbnail.childImageSharp.fluid} />
                      )}
                      <CardContent>
                        <Typography
                          gutterBottom
                          variant="subtitle1"
                          component="h3"
                        >
                          {title}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Link>
                </StyledCard>
              </Grid>
            )
          })}
      </Grid>
    </Container>
  )
}

ArticleListing.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  articles: PropTypes.array,
}

export default ArticleListing
